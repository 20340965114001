import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';

const IntlWrapper = ({ messages, locale = 'en', children }) => (
  <IntlProvider messages={messages} locale={locale} defaultLocale={locale} timeZone="UTC">
    {children}
  </IntlProvider>
);

IntlWrapper.propTypes = {
  messages: PropTypes.objectOf(PropTypes.string),
  locale: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
export default IntlWrapper;
